import { defineStore } from 'pinia';

export const useCommonStore = defineStore('common', {
  state: () => ({
    mobileMenuOpen: false,
    globalWarningVisible: false,
    searchVisible: false,
    typeVariant: null,
    sidebarPhoto: null,
    contactAccordionIndex: 0,
    pageColor: null,
    images: [],
    activeItem: 0,
    items: [],
  }),
  actions: {
    toggleMobileMenu() {
      this.mobileMenuOpen = !this.mobileMenuOpen;
    },
    toggleSearch() {
      this.searchVisible = !this.searchVisible;
    },
    toggleGlobalWarning() {
      this.globalWarningVisible = !this.globalWarningVisible;
    },
    setTypeVariant(value) {
      this.typeVariant = value;
    },
    setSidebarPhoto(value) {
      this.sidebarPhoto = value;
    },
    setContactsAccordionIndex(value) {
      this.contactAccordionIndex = value;
    },
    setPageColor(value) {
      this.pageColor = value;
    },
    setImages(value) {
      this.images = value;
    },
    setItems(value) {
      this.items = value;
    },
    setActiveItem(value) {
      this.activeItem = value;
    },
  },
  getters: {
    getTypeVariant: (state) => state.typeVariant,
    getPageColor: (state) => {
      return state.pageColor ? state.pageColor : '#f8f8f8';
    },
  },
});
